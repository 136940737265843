<script>
import {Modal as _BSModal} from "bootstrap";
import {mapActions, mapGetters} from "vuex";
// import {safeNumberConversion} from "@/utils/tools";

export default {
  name: "HandHistoryDetails",
  components: {
    HandHistoryDetailItem: () => import(/* webpackChunkName: "hand-history-detail-item-v2" */ '@/components/HandHistoryDetailItem.vue'),
  },
  props: {
    value: Boolean,
    roomId: Number,
  },
  data() {
    return {
      modal: false,
      expand: false,
    }
  },
  computed: {
    ...mapGetters(['token', 'sessionData']),
    ...mapGetters('user', ['gameHistory', 'gameWinners']),
    items() {
      if (this.roomId < 1) {
        return [];
      }

      return this.gameHistory
        .filter(({game_sn}) => game_sn === this.roomId)
        .sort((a, b) => (parseInt(b.game_uuid) || '0') - (parseInt(a.game_uuid) || '0'));
    },
    gameTitle() {
      if (this.items.length === 0) {
        return '';
      }
      return this.items[0].game_title;
    },
    smallBlind() {
      if (this.items.length === 0) {
        return '';
      }

      return this.items[0].small_blind;
    },
    bigBlind() {
      if (this.items.length === 0) {
        return '';
      }

      return this.items[0].big_blind;
    },
  },
  watch: {
    value() {
      this.value ? this.show() : this.hide();
    },
  },
  methods: {
    ...mapActions('user', {
      __fetchGameWinners: 'fetchGameWinners'
    }),
    hide() {
      this.modal.hide();
    },
    show() {
      this.modal.show();
    },
    shown() {
      this.$emit('input', true);
    },
    hidden() {
      this.$emit('input', false);
    },
    async onClickLoseHistory({game_sn, game_uuid}) {
      await this.__fetchGameWinners({
        token: this.token,
        game_sn,
        game_uuid,
      }).catch(function(e){
        console.log(e);
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.root.addEventListener('shown.bs.modal', this.shown, false);
      this.$refs.root.addEventListener('hidden.bs.modal', this.hidden, false);

      this.modal = _BSModal.getOrCreateInstance(this.$refs.root);
    });
  },
}
</script>

<template>
  <!-- Modal -->
  <div ref="root" class="modal basic_modal fade wrap_hand_history" id="hand_history_detail" aria-labelledby="hand_history" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen-lg-down">
      <div class="modal-content wrap_member">
        <div class="modal-header">
          <button type="button" class="btn_back" data-bs-dismiss="modal" aria-label="Close"></button>
          <h1 class="modal-title">핸드히스토리</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="list_header">
            <div class="tit">{{ gameTitle }}({{ smallBlind }}/{{ bigBlind }})</div>
            <button type="button" class="btn_list" data-bs-dismiss="modal" aria-label="Close"><img src="@/assets/v2/svg/ico_list.svg" alt=""></button>
          </div>
          <div class="list_detail">
            <HandHistoryDetailItem
              v-for="(item, index) in items"
              :key="index"
              :item="item"
              @winners="onClickLoseHistory"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped lang="scss">
.expand-enter-active, .expand-leave-active {
  transition: 0.5s ease-in-out;
}

.expand-enter, .expand-leave-to /* .expand-leave-active in <2.1.8 */
{
  transform: translateY(3rem);
  visibility: hidden;
  z-index: -1;
}
</style>